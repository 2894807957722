import * as React from 'react'
import TagManager from 'react-gtm-module'
import {contentfulClientCredentials, nucleobaseConfig} from 'config'
import {noop} from 'lodash'
import type {AppProps as NextAppProps} from 'next/app'
import Head from 'next/head'
import {useRouter} from 'next/router'
import NextAdapterPages from 'next-query-params/pages'
import {actionsMap} from 'services/globalActions'
import {QueryParamProvider} from 'use-query-params'

import {ContentfulModels, IPage} from '@invitae/contentful-types'
import {
  ComponentTextCopies,
  CountryCode,
  DEFAULT_COUNTRY,
  FlagValueDictionary,
  isServer,
  NucleobaseShell,
  ToggleConfig,
  useCountryLocationPrefix,
  useKeyPress,
} from '@invitae/nucleobase'

import {componentBlockMap} from 'components/componentBlockMap'
import Quiz from 'components/Quiz/Quiz'
import thirdPartyScripts from 'components/thirdPartyScripts'
import {useFeatureFlags} from 'hooks/useFeatureFlags'

import NotFoundPage from './404'
import InternalServerErrorPage from './500'

import '@invitae/nucleobase/dist/main.css'
import '@invitae/cms-blocks/dist/main.css'
import '@invitae/header-footer/dist/main.css'
import '@invitae/ssr-core/dist/main.css'
import '@invitae/physician-cart/dist/main.css'

const customComponentsMap = {
  [ContentfulModels.sectionQuiz]: Quiz,
}

const shellNucleobaseConfig = {
  componentBlockMap,
  context: {
    ...nucleobaseConfig,
  },
  customComponentsMap,
}

type AppProps = NextAppProps & {
  pageProps: {
    errorCode?: number
    countryCode?: CountryCode
    page?: IPage
    globalTextCopies?: Partial<ComponentTextCopies>
    domain?: string
    featureFlags?: FlagValueDictionary
  }
}

function App({Component, pageProps}: AppProps) {
  const router = useRouter()
  // const {featureFlags} = pageProps
  const {changeUrlPrefix} = useCountryLocationPrefix()
  const {featureFlags} = useFeatureFlags()

  const enterPress = useKeyPress('Enter')

  React.useEffect(() => {
    if (process.env.INVITAE__GTM_TAG_ID) {
      TagManager.initialize({
        gtmId: process.env.INVITAE__GTM_TAG_ID,
      })
    }

    if (process.env.ADOBE_LAUNCH_SCRIPT) {
      const script = document.createElement('script')
      script.src = `https://assets.adobedtm.com/${process.env.ADOBE_LAUNCH_SCRIPT}`
      script.async = true
      document.head.appendChild(script)
    }
  }, [])

  React.useEffect(() => {
    if (enterPress.pressed && (enterPress.keyboardEvent?.target as HTMLAnchorElement)?.href) {
      window.location.href = (enterPress.keyboardEvent?.target as HTMLAnchorElement)?.href
    }
  }, [enterPress])

  const pathname = !isServer() ? window.location.href : `${process.env.CANONICAL_HOSTNAME}${router.asPath}`

  const headerConfig = {
    canonicalPathname: pathname,
    facebookVerification: process.env.FACEBOOK_VERIFICATION,
  }

  const analyticsConfig = {
    currentPageUrl: router.asPath,
    previousPageUrl: typeof document !== 'undefined' ? document.referrer : '',
    ['Site location']: router.asPath,
  }

  const cartConfig = {
    apiParameters: {basePath: `${process.env.CART_BASE_PATH}`},
    featureFlags: featureFlags,
    gotoCartPage: () => {
      window.location.assign('/start-order/#/cart')
    },
    goToSignInPage: noop,
  }

  const toggleConfig: ToggleConfig = {
    featureFlags: featureFlags,
  }

  if (pageProps.errorCode) {
    return <InternalServerErrorPage />
  }

  if (!pageProps.page) {
    return <NotFoundPage />
  }

  const schemaMarkup = JSON.stringify({
    '@context': 'http://schema.org',
    '@id': pathname,
    '@type': 'WebSite',
    inLanguage: pageProps?.countryCode?.split('-')[0],
    publisher: {'@id': process.env.CANONICAL_HOSTNAME},
    translationOfWork: {'@id': `${changeUrlPrefix(pathname, 'US')}`},
    url: pathname,
    workTranslation: {'@id': pathname},
  })

  const isNotDefaultCountry = pageProps?.countryCode !== DEFAULT_COUNTRY

  const globalTextCopiesConfig = {
    enableClientSideRequest: false,
    textCopies: pageProps.globalTextCopies,
  }

  const globalActionsConfig = {
    actionsMap,
  }

  const locationConfig = {
    apiGateway: process.env.API_GATEWAY as string,
    domainFallback: pageProps?.domain as string,
  }

  return (
    <>
      {isNotDefaultCountry && <script type="application/ld+json">{schemaMarkup}</script>}
      <QueryParamProvider adapter={NextAdapterPages}>
        <NucleobaseShell
          Head={Head}
          analyticsConfig={analyticsConfig}
          cartConfig={cartConfig}
          contentfulConfig={contentfulClientCredentials}
          countryCode={pageProps?.countryCode}
          enableAnimations
          enableCountryLocationControls={false} // removes the country selector since we're US only now
          globalActionsConfig={globalActionsConfig}
          globalTextCopiesConfig={globalTextCopiesConfig}
          headerConfig={headerConfig}
          locationConfig={locationConfig}
          nucleobaseConfig={shellNucleobaseConfig}
          thirdPartyScripts={thirdPartyScripts}
          toggleConfig={toggleConfig}
          withBrowserHistory={router}
        >
          {pageProps?.page ?? <Component {...pageProps} />}
        </NucleobaseShell>
      </QueryParamProvider>
    </>
  )
}

export default App
